import { useState, useEffect } from "react";

import { getItem, removeItem, setItem } from "../local-storage/local-storage";

import { getHeaderFromResponse } from "./utils";

const gocardlessOrganisationIdHeader = "GoCardless-Organisation-ID";
const gocardlessOrganisationIdsHeaderStorageKey =
  "gc.api.headers.organisation-ids";
const onUpdateOrganisationIdFromResponseEventName =
  "onUpdateOrganisationIdFromResponse";

const getOrganisationIdFromResponseHeader = () =>
  getItem(gocardlessOrganisationIdsHeaderStorageKey) || [];

const prependOrganisationIdFromResponseHeader = (organisationId: string) =>
  setItem(gocardlessOrganisationIdsHeaderStorageKey, [
    ...new Set([organisationId, ...getOrganisationIdFromResponseHeader()]),
  ]);

export const removeOrganisationIdFromResponseHeader = () =>
  removeItem(gocardlessOrganisationIdsHeaderStorageKey);

export const syncOrganisationIdFromResponseHeaderHook = async (
  _request: Request,
  _options: object,
  response: Response
) => {
  if (!window) return;

  /*
   * When GoCardless Staff make a request to a "show" endpoint the
   * request will have a header with the resource’s organisation ID.
   */
  const organisationId = getHeaderFromResponse(
    response,
    gocardlessOrganisationIdHeader
  );

  if (!organisationId) return;

  prependOrganisationIdFromResponseHeader(organisationId);

  window.dispatchEvent(new Event(onUpdateOrganisationIdFromResponseEventName));
};

export const useOrganisationIdFromResponseHeader = () => {
  const [organisationIds, updateOrganisationId] = useState<string[]>([]);

  const updateAccessTokenListenerEvent = (event: StorageEvent) => {
    if (event.key === gocardlessOrganisationIdsHeaderStorageKey) {
      try {
        const storedOrganisationIds: string[] = JSON.parse(
          event.newValue || "[]"
        );
        updateOrganisationId(storedOrganisationIds);
      } catch {
        /* empty */
      }
    }
  };

  const updateOrganisationIdListener = () =>
    updateOrganisationId(getOrganisationIdFromResponseHeader());

  useEffect(() => {
    if (!window) return;

    updateOrganisationId(getOrganisationIdFromResponseHeader());

    window.addEventListener(
      onUpdateOrganisationIdFromResponseEventName,
      updateOrganisationIdListener
    );

    window.addEventListener("storage", updateAccessTokenListenerEvent);

    return () => {
      window.removeEventListener(
        onUpdateOrganisationIdFromResponseEventName,
        updateOrganisationIdListener
      );
      window.removeEventListener("storage", updateAccessTokenListenerEvent);
    };
  }, []);

  return organisationIds;
};
