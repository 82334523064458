import { AuthenticationFactorsStatus } from "@gocardless/api/dashboard/types";
import { useAuthenticationFactorList } from "@gocardless/api/dashboard/authentication-factor";
import { useEffect, useState } from "react";

import {
  getAuthenticationFactorId,
  getAuthenticationFactorPhoneNumber,
  getAuthenticationFactorType,
} from "./getAuthenticationFactorId";

export const useCurrentAuthFactor = () => {
  const [loading, setLoading] = useState(true);

  const { data: currentAuthFactor = {}, error } = useAuthenticationFactorList({
    is_default: true,
    status: AuthenticationFactorsStatus.Verified,
  });

  useEffect(() => {
    if (currentAuthFactor.authentication_factors || error) {
      setLoading(false);
    }
  }, [currentAuthFactor, error]);

  const id = getAuthenticationFactorId(currentAuthFactor);
  const factorType = getAuthenticationFactorType(currentAuthFactor);
  const phoneNumber = getAuthenticationFactorPhoneNumber(currentAuthFactor);

  return {
    currentAuthId: id,
    factorType,
    phoneNumber,
    loading,
  };
};
