import { AuthenticationFactorListResponseBody } from "@gocardless/api/dashboard/types";

export const getAuthenticationFactorId = (
  authenticationFactors: AuthenticationFactorListResponseBody
) => {
  const { authentication_factors } = authenticationFactors;

  return authentication_factors?.[0]?.id || "";
};

export const getAuthenticationFactorType = (
  authenticationFactors: AuthenticationFactorListResponseBody
) => {
  const { authentication_factors } = authenticationFactors;

  return authentication_factors?.[0]?.factor_type || "";
};

export const getAuthenticationFactorPhoneNumber = (
  authenticationFactors: AuthenticationFactorListResponseBody
) => {
  const { authentication_factors } = authenticationFactors;

  return authentication_factors?.[0]?.phone_number || "";
};
