import { useCallback, useEffect, useState } from "react";
import { Box, Color, Glyph, Icon } from "@gocardless/flux-react";
import { ExportsStatus } from "@gocardless/api/dashboard/types";
import { useExportSelf } from "@gocardless/api/dashboard/export";
import { useInterval } from "react-use";

import ExportsError from "./ExportsError";
import { onExportUpdateFunc } from "./ExportCentreConnect";
import { ExportItemRow } from "./ExportItemRow";

export const formatExportFileName = (id?: string, exportType?: string) =>
  `${exportType}-export-${id}.csv`;

const POLLING_INTERVAL = 5000;

const stateMapping = {
  [ExportsStatus.Successful]: (
    <Icon name={Glyph.Tick} color={Color.Success_Base} size="13px" />
  ),
  [ExportsStatus.Pending]: (
    <Icon name={Glyph.Spinner} color={Color.Success_Base} size="13px" />
  ),
  [ExportsStatus.Processing]: (
    <Icon name={Glyph.Spinner} color={Color.Success_Base} size="13px" />
  ),
  [ExportsStatus.Unsuccessful]: (
    <Icon name={Glyph.Close} color={Color.Alert_Base} size="13px" />
  ),
};

export const ExportStatus = ({ status }: { status?: ExportsStatus }) => (
  <Box gutterV={0} gutterH={0.5}>
    {status && stateMapping[status]}
  </Box>
);

const isExportFinished = (exportsStatus: ExportsStatus) =>
  exportsStatus === ExportsStatus.Successful ||
  exportsStatus === ExportsStatus.Unsuccessful;

interface ExportCentreBodyItemProps {
  totalFailures?: number;
  id: string;
  onExportUpdate: onExportUpdateFunc;
  isCentreOpen: boolean;
}

const ExportsCentreExportRowItem: React.FC<ExportCentreBodyItemProps> = (
  props
) => {
  const { totalFailures, id, onExportUpdate, isCentreOpen } = props;
  const { data: exp, revalidate } = useExportSelf(id);
  const [exportStatus, setExportStatus] = useState<ExportsStatus>(
    ExportsStatus.Pending
  );

  const revalidateExports = useCallback(() => {
    if (!isExportFinished(exportStatus)) {
      revalidate();
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus]);

  useEffect(() => {
    if (exp?.exports?.status) {
      setExportStatus(exp?.exports?.status);
    }
  }, [exp, setExportStatus]);

  useEffect(() => {
    exp && exp.exports && onExportUpdate(exp.exports);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus]);

  useInterval(
    revalidateExports,
    isExportFinished(exportStatus) ? null : POLLING_INTERVAL
  );

  return (
    <Box>
      {isCentreOpen && (
        <Box gutterH={2} gutterV={0.75}>
          <ExportItemRow key={id} {...props} {...exp?.exports} />
          {["unsuccessful", "expired"].includes(status) &&
            totalFailures === 1 && <ExportsError />}
        </Box>
      )}
    </Box>
  );
};

export default ExportsCentreExportRowItem;
