import { ButtonVariant, Color, Link, Text } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

interface ExportErrorProps {}

const ExportsError: React.FC<ExportErrorProps> = () => (
  <Text size={2} color={Color.Alert_500} data-testid="exports-error">
    <Trans id="exports.centre.error.start">
      Failed to complete. Please try again or
    </Trans>{" "}
    <Link href="mailto:help@gocardless.com" variant={ButtonVariant.TextOnLight}>
      <Text size={2} color={Color.Alert_Base} decoration="underline">
        <Trans id="exports.centre.error.contact">
          contact our support team
        </Trans>
      </Text>
    </Link>{" "}
    <Trans id="exports.centre.error.end">if the issue continues.</Trans>
  </Text>
);

export default ExportsError;
