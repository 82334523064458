import { useRouter } from "next/router";

const UntrackedRoutes = ["/password/edit", "/password/change"];

export const useTrackPath = () => {
  const router = useRouter();

  const shouldTrackPath = () =>
    !UntrackedRoutes.find((path) => path === router.pathname);

  return {
    shouldTrackPath,
  };
};
