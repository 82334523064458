import { Environment, getConfig, isEnvironment } from "src/common/config";
import Script from "next/script";

export const TranscendCookieScript = () => {
  const transcend = getConfig().client?.transcend;
  if (!transcend?.id) return null;

  const scriptPrefix = isEnvironment(Environment.Production)
    ? "https://transcend-cdn.com/cm"
    : "https://transcend-cdn.com/cm-test";

  return (
    <>
      <Script
        strategy="beforeInteractive"
        data-cfasync="false"
        data-regime="GDPR"
        data-report-only="on"
        data-dismissed-view-state="Closed"
        src={`${scriptPrefix}/${transcend.id}/airgap.js`}
      />
    </>
  );
};
