import {
  Box,
  BoxProps,
  Button,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import {
  showDrift,
  hideDrift,
} from "src/technical-integrations/drift/client/drift";
import {
  showZendesk,
  hideZendesk,
} from "src/technical-integrations/zendesk/client/zendesk";
export interface ExportCentreFooterProps extends BoxProps {
  dismiss: Function;
}

const ExportsCentreFooter: React.FC<ExportCentreFooterProps> = (props) => {
  const { dismiss } = props;
  hideDrift();
  hideZendesk();

  return (
    <Box
      gutterV={1}
      gutterH={1}
      justifyContent={JustifyContent.FlexEnd}
      layout="flex"
    >
      <Button
        size={ButtonSize.Sm}
        variant={ButtonVariant.PrimaryOnLight}
        data-testid="dismiss"
        onClick={() => {
          dismiss();
          showDrift();
          showZendesk();
        }}
      >
        <Trans id="Dismiss">Dismiss</Trans>
      </Button>
    </Box>
  );
};

export default ExportsCentreFooter;
