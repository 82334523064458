import { useTranscendConsent } from "src/technical-integrations/transcend";

import { GoogleAnalytics4Script } from "./google-analytics/GoogleAnalytics4";
import { RedditScript } from "./Reddit";

export const ThirdPartyTracking = () => {
  const { consent } = useTranscendConsent();

  return (
    <>
      <GoogleAnalytics4Script consent={consent} />
      <RedditScript consent={consent} />
    </>
  );
};
