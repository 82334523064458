export enum GA4Events {
  //MFA Setup
  MFASetupTOTPSuccess = "mfa_setup_totp_success",
  MFASetupSMSSuccess = "mfa_setup_sms_Success",
  MFASetupTOTPInitiate = "mfa_setup_totp_initiate",
  MFASetupSMSInitiate = "mfa_setup_sms_initiate",
  MFASetupTOTPError = "mfa_setup_totp_error",
  MFASetupSMSError = "mfa_setup_sms_error",
  MFASetupSMSPhoneNumberEntered = "sms_phone_number_entered",

  //Sign In
  SignInRecoveryCodeSuccess = "sign_in_recovery_code_success",
  SignInMFASuccess = "sign_in_mfa_success",
  SignInSuccess = "sign_in_success",

  //Sign Up
  SignUpSuccess = "sign_up_success",

  //Errors
  ErrorsUsageRestricted = "errors_usage_restricted",

  Payments = "payments",
}
