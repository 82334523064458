import { UserLocale } from "./locales";
/**
 * This indicates to Webpack 5 that .json files with @lingui/loader import must be
 * loaded with JS parser/generator.
 * https://github.com/lingui/js-lingui/issues/1048
 */
const catalogImports = {
  [UserLocale.DE_DE]: () =>
    import(`@lingui/loader!../../../catalogs/de-DE/messages.json?raw-lingui`),
  [UserLocale.EN_GB]: () =>
    import(`@lingui/loader!../../../catalogs/en-GB/messages.json?raw-lingui`),
  [UserLocale.EN_US]: () =>
    import(`@lingui/loader!../../../catalogs/en-US/messages.json?raw-lingui`),
  [UserLocale.FR_FR]: () =>
    import(`@lingui/loader!../../../catalogs/fr-FR/messages.json?raw-lingui`),
  [UserLocale.ES_ES]: () =>
    import(`@lingui/loader!../../../catalogs/es-ES/messages.json?raw-lingui`),
};

export const getCatalog = async (locale: UserLocale) => {
  const catalogImport =
    catalogImports[locale] || catalogImports[UserLocale.EN_GB];
  const catalogModule = await catalogImport();
  return catalogModule.messages;
};
