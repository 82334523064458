import Script from "next/script";

export enum CONSENT_TYPE {
  FUNCTIONAL = "functional",
  ANALYTICS = "analytics",
  ADVERTISING = "advertising",
}

interface ScriptProps {
  consentType: CONSENT_TYPE;
}

interface ExecutableScriptProps extends ScriptProps {
  executableScript: string;
}

interface GenericScriptProps extends ScriptProps {
  src: string;
}

export const ExecutableScript = (props: ExecutableScriptProps) => (
  <Script
    data-cookieconsent={props.consentType}
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: props.executableScript,
    }}
  />
);

export const GenericScript = (props: GenericScriptProps) => (
  <Script
    data-cookieconsent={props.consentType}
    strategy="afterInteractive"
    src={props.src}
  />
);
