import {
  Box,
  ButtonVariant,
  ColorPreset,
  FontWeight,
  Glyph,
  IconButton,
  JustifyContent,
  Text,
  TextAlign,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

export interface ExportsCentreHeaderProps {
  isCentreOpen: boolean;
  toggle: Function;
}

const ExportsCentreHeader: React.FC<ExportsCentreHeaderProps> = (props) => {
  const { toggle } = props;

  return (
    <Box
      layout="flex"
      gutterH={2}
      gutterV={1}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Text
        weight={FontWeight.SemiBold}
        textAlign={TextAlign.Left}
        size={4}
        color={ColorPreset.TextOnLight_02}
      >
        <Trans id="Exports">Exports</Trans>
      </Text>
      {props.isCentreOpen ? (
        <Box data-testid="chevron-down">
          <IconButton
            icon={Glyph.ChevronDown}
            variant={ButtonVariant.TextOnLight}
            onClick={() => toggle(!props.isCentreOpen)}
            label="toggle-close"
          />
        </Box>
      ) : (
        <Box data-testid="chevron-up">
          <IconButton
            icon={Glyph.ChevronUp}
            variant={ButtonVariant.TextOnLight}
            label="toggle-open"
            onClick={() => toggle(!props.isCentreOpen)}
          />
        </Box>
      )}
    </Box>
  );
};

export default ExportsCentreHeader;
