import { Trans } from "@lingui/macro";
import { createContext, useContext, useCallback, useReducer } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  TwoFADialogVariant,
  TwoFADialog,
  TwoFADialogConfig,
} from "src/components/dialogs/TwoFADialog";
import { useAPIErrorNotifications } from "src/hooks/useAPIErrorNotifications";
import { HTTPError } from "@gocardless/api/utils";

import { ToTranslate } from "./i18n";

export type TwoFASubmitCallback = (otpCode: string) => Promise<void>;
export type TwoFACloseCallback = () => void;
export type TwoFAErrorCallback = (error: HTTPError) => void;

export interface TwoFactorAuthFormFields {
  otpCode: string;
}

interface TwoFAContextType {
  showTwoFADialog: (params: {
    variant: TwoFADialogVariant;
    onSubmit: TwoFASubmitCallback;
    onClose?: TwoFACloseCallback;
    onError?: TwoFAErrorCallback;
    customConfig?: Partial<TwoFADialogConfig>;
  }) => void;
}

const TwoFAContext = createContext<TwoFAContextType | undefined>(undefined);

interface TwoFAState {
  isOpen: boolean;
  isSubmitting: boolean;
  variant: TwoFADialogVariant | null;
  submitCallback: TwoFASubmitCallback | null;
  closeCallback: TwoFACloseCallback | null;
  errorCallback: TwoFAErrorCallback | null;
  customConfig: Partial<TwoFADialogConfig> | null;
}

type TwoFAAction =
  | {
      type: "SHOW_DIALOG";
      payload: {
        variant: TwoFADialogVariant;
        onSubmit: TwoFASubmitCallback;
        onClose?: TwoFACloseCallback;
        onError?: TwoFAErrorCallback;
        config?: Partial<TwoFADialogConfig>;
      };
    }
  | { type: "CLOSE_DIALOG" }
  | { type: "SET_SUBMITTING"; payload: boolean };

const initialState: TwoFAState = {
  isOpen: false,
  isSubmitting: false,
  variant: null,
  submitCallback: null,
  closeCallback: null,
  errorCallback: null,
  customConfig: null,
};

const twoFAReducer = (state: TwoFAState, action: TwoFAAction): TwoFAState => {
  switch (action.type) {
    case "SHOW_DIALOG":
      return {
        ...state,
        isOpen: true,
        variant: action.payload.variant,
        submitCallback: action.payload.onSubmit,
        closeCallback: action.payload.onClose || null,
        errorCallback: action.payload.onError || null,
        customConfig: action.payload.config || null,
      };
    case "CLOSE_DIALOG":
      return {
        ...state,
        isOpen: false,
        variant: null,
        submitCallback: null,
        closeCallback: null,
        errorCallback: null,
        customConfig: null,
      };
    case "SET_SUBMITTING":
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export const TwoFAContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(twoFAReducer, initialState);
  const formMethods = useForm<TwoFactorAuthFormFields>({
    mode: "onChange",
  });

  const { showErrors: triggerErrorNotifications } = useAPIErrorNotifications({
    title: <Trans id="Error">Error</Trans>,
    message: (
      <ToTranslate>
        Something went wrong. Please try again or contact support if the problem
        persists.
      </ToTranslate>
    ),
  });

  const handleClose = useCallback(() => {
    dispatch({ type: "CLOSE_DIALOG" });
    formMethods.reset();
    state.closeCallback?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, state.closeCallback]);

  const handleSubmit = useCallback(async () => {
    if (!state.submitCallback) return;

    try {
      dispatch({ type: "SET_SUBMITTING", payload: true });
      const otpCode = formMethods.getValues("otpCode");
      await state.submitCallback(otpCode);
      handleClose();
    } catch (error) {
      if (state.errorCallback) {
        state.errorCallback(error as HTTPError);
      } else {
        triggerErrorNotifications();
      }
    } finally {
      dispatch({ type: "SET_SUBMITTING", payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.submitCallback,
    formMethods,
    handleClose,
    triggerErrorNotifications,
  ]);

  const showTwoFADialog = useCallback(
    ({
      variant,
      onSubmit,
      onClose,
      onError,
      customConfig: config,
    }: {
      variant: TwoFADialogVariant;
      onSubmit: TwoFASubmitCallback;
      onClose?: TwoFACloseCallback;
      onError?: TwoFAErrorCallback;
      customConfig?: Partial<TwoFADialogConfig>;
    }) => {
      dispatch({
        type: "SHOW_DIALOG",
        payload: { variant, onSubmit, onClose, onError, config },
      });
    },
    []
  );

  return (
    <TwoFAContext.Provider value={{ showTwoFADialog }}>
      {children}
      {state.variant && (
        <FormProvider {...formMethods}>
          <TwoFADialog
            open={state.isOpen}
            onClose={handleClose}
            onSubmit={handleSubmit}
            isSubmitting={state.isSubmitting}
            variant={state.variant}
            config={state.customConfig}
          />
        </FormProvider>
      )}
    </TwoFAContext.Provider>
  );
};

export const useTwoFA = () => {
  const context = useContext(TwoFAContext);
  if (!context) {
    throw new Error("useTwoFA must be used within a TwoFAProvider");
  }
  return context;
};
