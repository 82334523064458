import {
  defaultExport,
  ExportsLocalStorageCache,
} from "src/components/ui/export-centre/ExportCentreConnect";
import { createContext, useState } from "react";
import { useExportsLocalStorage } from "src/common/hooks/useExportsLocalStorage";
import { PayerFraudConfigurationResource } from "@gocardless/api/dashboard/types";

export interface GlobalStateType {
  exports: ExportsLocalStorageCache;
  setExports: (e: ExportsLocalStorageCache) => void;

  payerFraudConfiguration: PayerFraudConfigurationResource | undefined;
  setPayerFraudConfiguration: (
    payerFraudConfiguration: PayerFraudConfigurationResource | undefined
  ) => void;

  bannersHeight: number;
  setBannersHeight: (height: number) => void;
  showLayoutBanners: boolean;
  setShowLayoutBanners: (show: boolean) => void;
}

const initialState: GlobalStateType = {
  exports: defaultExport,
  setExports: () => {},

  payerFraudConfiguration: undefined,
  setPayerFraudConfiguration: () => {},
  bannersHeight: 0,
  setBannersHeight: () => {},
  showLayoutBanners: false,
  setShowLayoutBanners: () => {},
};

export const GlobalState = createContext(initialState);

export const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [state, setState] = useState(initialState);
  const [, persistExports] = useExportsLocalStorage();
  const [bannersHeight, setBannersHeight] = useState(0);
  const [showLayoutBanners, setShowLayoutBanners] = useState(false);

  const setExports = (exports: ExportsLocalStorageCache) => {
    const sortedExports = {
      ...exports,
      pendingExports: exports.pendingExports.sort(),
      completedExports: exports.completedExports.sort(),
      failedExports: exports.failedExports.sort(),
    };
    setState({ ...state, exports: sortedExports });
    persistExports(exports);
  };

  const setPayerFraudConfiguration = (
    payerFraudConfiguration: PayerFraudConfigurationResource | undefined
  ) => {
    setState({ ...state, payerFraudConfiguration });
  };

  return (
    <GlobalState.Provider
      value={{
        ...state,
        setExports,
        setPayerFraudConfiguration,
        bannersHeight,
        setBannersHeight,
        showLayoutBanners,
        setShowLayoutBanners,
      }}
    >
      {props.children}
    </GlobalState.Provider>
  );
};
