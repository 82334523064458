import {
  Box,
  Color,
  ColorPreset,
  JustifyContent,
  Separator,
} from "@gocardless/flux-react";

import ExportsCentreHeader from "./ExportsCentreHeader";
import ExportsCentreBody from "./ExportsCentreBody";
import ExportsCentreFooter from "./ExportsCentreFooter";
import { onExportUpdateFunc } from "./ExportCentreConnect";

export type ExportID = string;

const Divider = () => <Separator color={ColorPreset.BorderOnLight_04} />;

export interface ExportsCentreProps {
  completedExports: Array<ExportID>;
  dismiss: Function;
  failedExports: Array<ExportID>;
  isShowingCentre: boolean;
  isCentreOpen: boolean;
  isUserLoggedIn: boolean;
  pendingExports: Array<ExportID>;
  toggle: (currentState: boolean) => void;
  onExportUpdate: onExportUpdateFunc;
}

const ExportsCentre: React.FC<ExportsCentreProps> = (props) => {
  const {
    completedExports,
    isShowingCentre,
    isCentreOpen,
    pendingExports,
    failedExports,
    isUserLoggedIn,
    toggle,
  } = props;

  const hasPendingExports = pendingExports.length > 0;
  const hasCompletedExports = completedExports.length > 0;
  const hasFailedExports = failedExports.length > 0;

  const hasExports =
    hasPendingExports || hasCompletedExports || hasFailedExports;
  if (!isShowingCentre || !hasExports || !isUserLoggedIn) return null;

  return (
    <Box
      borderRadius={1}
      elevation={3}
      bg={Color.White}
      justifyContent={JustifyContent.SpaceBetween}
      maxWidth={475}
      style={{ width: "464px", position: "relative" }}
    >
      <ExportsCentreHeader
        {...props}
        toggle={(currentState: boolean) => {
          toggle(currentState);
        }}
        isCentreOpen={isCentreOpen}
      />
      <Divider />
      <ExportsCentreBody {...props} isCentreOpen={isCentreOpen} />
      <Divider />
      <ExportsCentreFooter {...props} />
    </Box>
  );
};

export default ExportsCentre;
