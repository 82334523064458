import {
  errorNotificationHandler,
  RATE_LIMIT_EXCEEDED_MSG,
} from "src/common/notifications/notificationErrorHandlers";
import { useToastNotification } from "src/hooks/useToastNotification";
import { useAuthenticationFactorTrigger } from "@gocardless/api/dashboard/authentication-factor";

import { MFA_RATE_LIMIT_EXCEEDED } from "./notifications";

export const useTriggerAuthFactor = (
  authenticationId: string,
  onSuccess?: () => void
) => {
  const { triggerErrorNotification } = useToastNotification();

  const [submit] = useAuthenticationFactorTrigger(authenticationId, {
    onSuccess,
    onError: async (err) => {
      errorNotificationHandler(err, triggerErrorNotification, {
        match: RATE_LIMIT_EXCEEDED_MSG,
        content: MFA_RATE_LIMIT_EXCEEDED,
      });
    },
  });

  const triggerMFA = () => submit({});

  return {
    triggerMFA,
  };
};
