import { useEffect } from "react";
import { useRouter, RouterEvent } from "next/router";

export const useHandleRouteChange = (
  routeEvent: RouterEvent,
  callback: (url: string) => void
) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      callback(path);
    };

    router.events.on(routeEvent, handleRouteChange);

    return () => {
      router.events.off(routeEvent, handleRouteChange);
    };
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);
};
