/* eslint-disable */
const jwt = require("jsonwebtoken");

const tl = function (user, credentials) {
  const zlogin = document.getElementById("ze-login");
  if (zlogin) {
    return;
  }
  const snipet = document.getElementById("ze-snippet");
  snipet.onload = () => {
    // need to specify jsonwebtoken package version to 8.5.1 because in 9.0.0
    // sign function do not work since Mar 14, 2023
    // https://github.com/auth0/node-jsonwebtoken/issues/892

    var payload = {
      email: user.email,
      name:
        [user.given_name, user.family_name].filter(Boolean).join(" ") ||
        user.email,
      email_verified: true,
      scope: "user",
    };
    let token = jwt.sign(payload, credentials.jwt_secret, {
      header: { kid: credentials.jwt_id },
      expiresIn: "30d",
    });
    /*
        requirements for JWT: https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/enabling_auth_visitors/
        credentials: https://gocardless1710242047.zendesk.com/admin/account/security/end_users#messaging
      */

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.id = "ze-login";
    s.text += `zE("messenger", "loginUser", function (callback) {
          callback("${token}")
        })
       ;`;
    const h = document.getElementsByTagName("head")[0];
    h.appendChild(s);
  };
};

export const hideZendesk = () => {
  if (window?.zE) {
    window.zE("messenger", "hide");
  }
};

export const showZendesk = () => {
  if (window?.zE) {
    window.zE("messenger", "show");
  }
};

export const loadZendeskScript = (addId, user, credentials) => {
  const w = window;
  const d = document;
  const l = function () {
    const snipet = document.getElementById("ze-snippet");
    if (!snipet) {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.id = "ze-snippet";
      s.src = `https://static.zdassets.com/ekr/snippet.js?key=${addId}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
  };
  if (document.readyState === "complete") {
    l();
    tl(user, credentials);
  } else if (w.attachEvent) {
    w.attachEvent("onload", l);
  } else {
    w.addEventListener("load", l, false);
  }
};
