import { Route, getRoute } from "src/common/routing";

/**
 * Determines if the given pathName corresponds to the Zendesk Forms Interceptor route.
 * It extracts the form ID from the pathName and compares the resolved route for that ID
 * with the provided pathName to check for a match.
 *
 * @param {string} pathName - The path name to check against the Zendesk Forms Interceptor route.
 * @returns {boolean}
 */
export const isZendeskFormsInterceptorRoute = (pathName: string) => {
  /**
   * Note: Consider refactoring the pathName matching logic to use the URLPattern API
   * once it is implemented by all major browsers for more robust pattern matching.
   *
   * @see https://urlpattern.spec.whatwg.org/#urlpattern - URLPattern API specification.
   * @see https://caniuse.com/?search=urlpattern - Browser support for URLPattern API.
   */
  const formId = pathName.split("/").pop();

  if (!formId?.length) {
    return false;
  }

  const resolvedRoute = getRoute(Route.ZendeskFormsInterceptor, { formId });

  return resolvedRoute === pathName;
};
