import { Trans } from "@lingui/macro";

export const SMS_CODE_SENT = {
  title: <Trans id="settings.user.mfa.sms.code-sent-title">Code sent</Trans>,
  message: (
    <Trans id="settings.user.mfa.sms.code-sent-description">
      We have resent a code via SMS to your phone number
    </Trans>
  ),
};

export const MFA_RATE_LIMIT_EXCEEDED = {
  title: (
    <Trans id="settings.user.mfa.error.rate-limit-title">
      Please try again in 1 minute
    </Trans>
  ),
  message: (
    <Trans id="settings.user.mfa.error.rate-limit-description">
      We need to wait 1 minute before resending another code.
    </Trans>
  ),
};
