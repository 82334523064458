import { Box, ZIndex } from "@gocardless/flux-react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import { ExportsCentreConnect } from "./ExportCentreConnect";

export const ExportCentreContainer: React.FC = () => {
  const { data: user } = useUserShowSelf();
  const userId = user?.users?.id;
  if (!userId) return <></>;

  return (
    <Box
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: ZIndex.Dialog,
      }}
    >
      <ExportsCentreConnect />
    </Box>
  );
};
