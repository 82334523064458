import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

export const COUNTRY_CODES = (
  i18n: I18n
): {
  code: string;
  name: string;
  is_union_territory: boolean;
}[] => [
  {
    code: "GB",
    name: i18n._(
      t({ id: "country_name.united_kingdom", message: "United Kingdom" })
    ),
    is_union_territory: false,
  },
  {
    code: "AF",
    name: i18n._(t({ id: "country_name.afghanistan", message: "Afghanistan" })),
    is_union_territory: false,
  },
  {
    code: "AX",
    name: i18n._(
      t({ id: "country_name.aland_islands", message: "Åland Islands" })
    ),
    is_union_territory: true,
  },
  {
    code: "AL",
    name: i18n._(t({ id: "country_name.albania", message: "Albania" })),
    is_union_territory: false,
  },
  {
    code: "DZ",
    name: i18n._(t({ id: "country_name.algeria", message: "Algeria" })),
    is_union_territory: false,
  },
  {
    code: "AS",
    name: i18n._(
      t({ id: "country_name.american_samoa", message: "American Samoa" })
    ),
    is_union_territory: true,
  },
  {
    code: "AD",
    name: i18n._(t({ id: "country_name.andorra", message: "Andorra" })),
    is_union_territory: false,
  },
  {
    code: "AO",
    name: i18n._(t({ id: "country_name.angola", message: "Angola" })),
    is_union_territory: false,
  },
  {
    code: "AI",
    name: i18n._(t({ id: "country_name.anguilla", message: "Anguilla" })),
    is_union_territory: true,
  },
  {
    code: "AQ",
    name: i18n._(t({ id: "country_name.antarctica", message: "Antarctica" })),
    is_union_territory: true,
  },
  {
    code: "AG",
    name: i18n._(
      t({
        id: "country_name.antigua_and_barbuda",
        message: "Antigua and Barbuda",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "AR",
    name: i18n._(t({ id: "country_name.argentina", message: "Argentina" })),
    is_union_territory: false,
  },
  {
    code: "AM",
    name: i18n._(t({ id: "country_name.armenia", message: "Armenia" })),
    is_union_territory: false,
  },
  {
    code: "AW",
    name: i18n._(t({ id: "country_name.aruba", message: "Aruba" })),
    is_union_territory: true,
  },
  {
    code: "AU",
    name: i18n._(t({ id: "country_name.australia", message: "Australia" })),
    is_union_territory: false,
  },
  {
    code: "AT",
    name: i18n._(t({ id: "country_name.austria", message: "Austria" })),
    is_union_territory: false,
  },
  {
    code: "AZ",
    name: i18n._(t({ id: "country_name.azerbaijan", message: "Azerbaijan" })),
    is_union_territory: false,
  },
  {
    code: "BS",
    name: i18n._(t({ id: "country_name.bahamas", message: "Bahamas" })),
    is_union_territory: false,
  },
  {
    code: "BH",
    name: i18n._(t({ id: "country_name.bahrain", message: "Bahrain" })),
    is_union_territory: false,
  },
  {
    code: "BD",
    name: i18n._(t({ id: "country_name.bangladesh", message: "Bangladesh" })),
    is_union_territory: false,
  },
  {
    code: "BB",
    name: i18n._(t({ id: "country_name.barbados", message: "Barbados" })),
    is_union_territory: false,
  },
  {
    code: "BY",
    name: i18n._(t({ id: "country_name.belarus", message: "Belarus" })),
    is_union_territory: false,
  },
  {
    code: "BE",
    name: i18n._(t({ id: "country_name.belgium", message: "Belgium" })),
    is_union_territory: false,
  },
  {
    code: "BZ",
    name: i18n._(t({ id: "country_name.belize", message: "Belize" })),
    is_union_territory: false,
  },
  {
    code: "BJ",
    name: i18n._(t({ id: "country_name.benin", message: "Benin" })),
    is_union_territory: false,
  },
  {
    code: "BM",
    name: i18n._(t({ id: "country_name.bermuda", message: "Bermuda" })),
    is_union_territory: true,
  },
  {
    code: "BT",
    name: i18n._(t({ id: "country_name.bhutan", message: "Bhutan" })),
    is_union_territory: false,
  },
  {
    code: "BO",
    name: i18n._(
      t({
        id: "country_name.bolivia_plurinational_state_of",
        message: "Bolivia (Plurinational State of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "BQ",
    name: i18n._(
      t({
        id: "country_name.bonaire_sint_eustatius_and_saba",
        message: "Bonaire, Sint Eustatius and Saba",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "BA",
    name: i18n._(
      t({
        id: "country_name.bosnia_and_herzegovina",
        message: "Bosnia and Herzegovina",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "BW",
    name: i18n._(t({ id: "country_name.botswana", message: "Botswana" })),
    is_union_territory: false,
  },
  {
    code: "BV",
    name: i18n._(
      t({ id: "country_name.bouvet_island", message: "Bouvet Island" })
    ),
    is_union_territory: true,
  },
  {
    code: "BR",
    name: i18n._(t({ id: "country_name.brazil", message: "Brazil" })),
    is_union_territory: false,
  },
  {
    code: "IO",
    name: i18n._(
      t({
        id: "country_name.british_indian_ocean_territory",
        message: "British Indian Ocean Territory",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "UM",
    name: i18n._(
      t({
        id: "country_name.united_states_minor_outlying_islands",
        message: "United States Minor Outlying Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "VG",
    name: i18n._(
      t({
        id: "country_name.virgin_islands_british",
        message: "Virgin Islands (British)",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "VI",
    name: i18n._(
      t({
        id: "country_name.virgin_islands_us",
        message: "Virgin Islands (U.S.)",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "BN",
    name: i18n._(
      t({ id: "country_name.brunei_darussalam", message: "Brunei Darussalam" })
    ),
    is_union_territory: false,
  },
  {
    code: "BG",
    name: i18n._(t({ id: "country_name.bulgaria", message: "Bulgaria" })),
    is_union_territory: false,
  },
  {
    code: "BF",
    name: i18n._(
      t({ id: "country_name.burkina_faso", message: "Burkina Faso" })
    ),
    is_union_territory: false,
  },
  {
    code: "BI",
    name: i18n._(t({ id: "country_name.burundi", message: "Burundi" })),
    is_union_territory: false,
  },
  {
    code: "KH",
    name: i18n._(t({ id: "country_name.cambodia", message: "Cambodia" })),
    is_union_territory: false,
  },
  {
    code: "CM",
    name: i18n._(t({ id: "country_name.cameroon", message: "Cameroon" })),
    is_union_territory: false,
  },
  {
    code: "CA",
    name: i18n._(t({ id: "country_name.canada", message: "Canada" })),
    is_union_territory: false,
  },
  {
    code: "CV",
    name: i18n._(t({ id: "country_name.cabo_verde", message: "Cabo Verde" })),
    is_union_territory: false,
  },
  {
    code: "KY",
    name: i18n._(
      t({ id: "country_name.cayman_islands", message: "Cayman Islands" })
    ),
    is_union_territory: true,
  },
  {
    code: "CF",
    name: i18n._(
      t({
        id: "country_name.central_african_republic",
        message: "Central African Republic",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "TD",
    name: i18n._(t({ id: "country_name.chad", message: "Chad" })),
    is_union_territory: false,
  },
  {
    code: "CL",
    name: i18n._(t({ id: "country_name.chile", message: "Chile" })),
    is_union_territory: false,
  },
  {
    code: "CN",
    name: i18n._(t({ id: "country_name.china", message: "China" })),
    is_union_territory: false,
  },
  {
    code: "CX",
    name: i18n._(
      t({ id: "country_name.christmas_island", message: "Christmas Island" })
    ),
    is_union_territory: true,
  },
  {
    code: "CC",
    name: i18n._(
      t({
        id: "country_name.cocos_keeling_islands",
        message: "Cocos (Keeling) Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "CO",
    name: i18n._(t({ id: "country_name.colombia", message: "Colombia" })),
    is_union_territory: false,
  },
  {
    code: "KM",
    name: i18n._(t({ id: "country_name.comoros", message: "Comoros" })),
    is_union_territory: false,
  },
  {
    code: "CG",
    name: i18n._(t({ id: "country_name.congo", message: "Congo" })),
    is_union_territory: false,
  },
  {
    code: "CD",
    name: i18n._(
      t({
        id: "country_name.congo_democratic_republic_of_the",
        message: "Congo (Democratic Republic of the)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "CK",
    name: i18n._(
      t({ id: "country_name.cook_islands", message: "Cook Islands" })
    ),
    is_union_territory: true,
  },
  {
    code: "CR",
    name: i18n._(t({ id: "country_name.costa_rica", message: "Costa Rica" })),
    is_union_territory: false,
  },
  {
    code: "HR",
    name: i18n._(t({ id: "country_name.croatia", message: "Croatia" })),
    is_union_territory: false,
  },
  {
    code: "CU",
    name: i18n._(t({ id: "country_name.cuba", message: "Cuba" })),
    is_union_territory: false,
  },
  {
    code: "CW",
    name: i18n._(t({ id: "country_name.curacao", message: "Curaçao" })),
    is_union_territory: true,
  },
  {
    code: "CY",
    name: i18n._(t({ id: "country_name.cyprus", message: "Cyprus" })),
    is_union_territory: false,
  },
  {
    code: "CZ",
    name: i18n._(
      t({ id: "country_name.czech_republic", message: "Czech Republic" })
    ),
    is_union_territory: false,
  },
  {
    code: "DK",
    name: i18n._(t({ id: "country_name.denmark", message: "Denmark" })),
    is_union_territory: false,
  },
  {
    code: "DJ",
    name: i18n._(t({ id: "country_name.djibouti", message: "Djibouti" })),
    is_union_territory: false,
  },
  {
    code: "DM",
    name: i18n._(t({ id: "country_name.dominica", message: "Dominica" })),
    is_union_territory: false,
  },
  {
    code: "DO",
    name: i18n._(
      t({
        id: "country_name.dominican_republic",
        message: "Dominican Republic",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "EC",
    name: i18n._(t({ id: "country_name.ecuador", message: "Ecuador" })),
    is_union_territory: false,
  },
  {
    code: "EG",
    name: i18n._(t({ id: "country_name.egypt", message: "Egypt" })),
    is_union_territory: false,
  },
  {
    code: "SV",
    name: i18n._(t({ id: "country_name.el_salvador", message: "El Salvador" })),
    is_union_territory: false,
  },
  {
    code: "GQ",
    name: i18n._(
      t({ id: "country_name.equatorial_guinea", message: "Equatorial Guinea" })
    ),
    is_union_territory: false,
  },
  {
    code: "ER",
    name: i18n._(t({ id: "country_name.eritrea", message: "Eritrea" })),
    is_union_territory: false,
  },
  {
    code: "EE",
    name: i18n._(t({ id: "country_name.estonia", message: "Estonia" })),
    is_union_territory: false,
  },
  {
    code: "ET",
    name: i18n._(t({ id: "country_name.ethiopia", message: "Ethiopia" })),
    is_union_territory: false,
  },
  {
    code: "FK",
    name: i18n._(
      t({
        id: "country_name.falkland_islands_malvinas",
        message: "Falkland Islands (Malvinas)",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "FO",
    name: i18n._(
      t({ id: "country_name.faroe_islands", message: "Faroe Islands" })
    ),
    is_union_territory: true,
  },
  {
    code: "FJ",
    name: i18n._(t({ id: "country_name.fiji", message: "Fiji" })),
    is_union_territory: false,
  },
  {
    code: "FI",
    name: i18n._(t({ id: "country_name.finland", message: "Finland" })),
    is_union_territory: false,
  },
  {
    code: "FR",
    name: i18n._(t({ id: "country_name.france", message: "France" })),
    is_union_territory: false,
  },
  {
    code: "FR-COR",
    name: i18n._(t({ id: "country_name.corsica", message: "Corsica" })),
    is_union_territory: false,
  },
  {
    code: "GF",
    name: i18n._(
      t({ id: "country_name.french_guiana", message: "French Guiana" })
    ),
    is_union_territory: true,
  },
  {
    code: "PF",
    name: i18n._(
      t({ id: "country_name.french_polynesia", message: "French Polynesia" })
    ),
    is_union_territory: true,
  },
  {
    code: "TF",
    name: i18n._(
      t({
        id: "country_name.french_southern_territories",
        message: "French Southern Territories",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "GA",
    name: i18n._(t({ id: "country_name.gabon", message: "Gabon" })),
    is_union_territory: false,
  },
  {
    code: "GM",
    name: i18n._(t({ id: "country_name.gambia", message: "Gambia" })),
    is_union_territory: false,
  },
  {
    code: "GE",
    name: i18n._(t({ id: "country_name.georgia", message: "Georgia" })),
    is_union_territory: false,
  },
  {
    code: "DE",
    name: i18n._(t({ id: "country_name.germany", message: "Germany" })),
    is_union_territory: false,
  },
  {
    code: "GH",
    name: i18n._(t({ id: "country_name.ghana", message: "Ghana" })),
    is_union_territory: false,
  },
  {
    code: "GI",
    name: i18n._(t({ id: "country_name.gibraltar", message: "Gibraltar" })),
    is_union_territory: true,
  },
  {
    code: "GR",
    name: i18n._(t({ id: "country_name.greece", message: "Greece" })),
    is_union_territory: false,
  },
  {
    code: "GL",
    name: i18n._(t({ id: "country_name.greenland", message: "Greenland" })),
    is_union_territory: true,
  },
  {
    code: "GD",
    name: i18n._(t({ id: "country_name.grenada", message: "Grenada" })),
    is_union_territory: false,
  },
  {
    code: "GP",
    name: i18n._(t({ id: "country_name.guadeloupe", message: "Guadeloupe" })),
    is_union_territory: true,
  },
  {
    code: "GU",
    name: i18n._(t({ id: "country_name.guam", message: "Guam" })),
    is_union_territory: true,
  },
  {
    code: "GT",
    name: i18n._(t({ id: "country_name.guatemala", message: "Guatemala" })),
    is_union_territory: false,
  },
  {
    code: "GG",
    name: i18n._(t({ id: "country_name.guernsey", message: "Guernsey" })),
    is_union_territory: true,
  },
  {
    code: "GN",
    name: i18n._(t({ id: "country_name.guinea", message: "Guinea" })),
    is_union_territory: false,
  },
  {
    code: "GW",
    name: i18n._(
      t({ id: "country_name.guineabissau", message: "Guinea-Bissau" })
    ),
    is_union_territory: false,
  },
  {
    code: "GY",
    name: i18n._(t({ id: "country_name.guyana", message: "Guyana" })),
    is_union_territory: false,
  },
  {
    code: "HT",
    name: i18n._(t({ id: "country_name.haiti", message: "Haiti" })),
    is_union_territory: false,
  },
  {
    code: "HM",
    name: i18n._(
      t({
        id: "country_name.heard_island_and_mcdonald_islands",
        message: "Heard Island and McDonald Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "VA",
    name: i18n._(t({ id: "country_name.holy_see", message: "Holy See" })),
    is_union_territory: false,
  },
  {
    code: "HN",
    name: i18n._(t({ id: "country_name.honduras", message: "Honduras" })),
    is_union_territory: false,
  },
  {
    code: "HK",
    name: i18n._(t({ id: "country_name.hong_kong", message: "Hong Kong" })),
    is_union_territory: false,
  },
  {
    code: "HU",
    name: i18n._(t({ id: "country_name.hungary", message: "Hungary" })),
    is_union_territory: false,
  },
  {
    code: "IS",
    name: i18n._(t({ id: "country_name.iceland", message: "Iceland" })),
    is_union_territory: false,
  },
  {
    code: "IN",
    name: i18n._(t({ id: "country_name.india", message: "India" })),
    is_union_territory: false,
  },
  {
    code: "ID",
    name: i18n._(t({ id: "country_name.indonesia", message: "Indonesia" })),
    is_union_territory: false,
  },
  {
    code: "CI",
    name: i18n._(
      t({ id: "country_name.cote_divoire", message: "Côte d'Ivoire" })
    ),
    is_union_territory: false,
  },
  {
    code: "IR",
    name: i18n._(
      t({
        id: "country_name.iran_islamic_republic_of",
        message: "Iran (Islamic Republic of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "IQ",
    name: i18n._(t({ id: "country_name.iraq", message: "Iraq" })),
    is_union_territory: false,
  },
  {
    code: "IE",
    name: i18n._(t({ id: "country_name.ireland", message: "Ireland" })),
    is_union_territory: false,
  },
  {
    code: "IM",
    name: i18n._(t({ id: "country_name.isle_of_man", message: "Isle of Man" })),
    is_union_territory: true,
  },
  {
    code: "IL",
    name: i18n._(t({ id: "country_name.israel", message: "Israel" })),
    is_union_territory: false,
  },
  {
    code: "IT",
    name: i18n._(t({ id: "country_name.italy", message: "Italy" })),
    is_union_territory: false,
  },
  {
    code: "JM",
    name: i18n._(t({ id: "country_name.jamaica", message: "Jamaica" })),
    is_union_territory: false,
  },
  {
    code: "JP",
    name: i18n._(t({ id: "country_name.japan", message: "Japan" })),
    is_union_territory: false,
  },
  {
    code: "JE",
    name: i18n._(t({ id: "country_name.jersey", message: "Jersey" })),
    is_union_territory: true,
  },
  {
    code: "JO",
    name: i18n._(t({ id: "country_name.jordan", message: "Jordan" })),
    is_union_territory: false,
  },
  {
    code: "KZ",
    name: i18n._(t({ id: "country_name.kazakhstan", message: "Kazakhstan" })),
    is_union_territory: false,
  },
  {
    code: "KE",
    name: i18n._(t({ id: "country_name.kenya", message: "Kenya" })),
    is_union_territory: false,
  },
  {
    code: "KI",
    name: i18n._(t({ id: "country_name.kiribati", message: "Kiribati" })),
    is_union_territory: false,
  },
  {
    code: "KW",
    name: i18n._(t({ id: "country_name.kuwait", message: "Kuwait" })),
    is_union_territory: false,
  },
  {
    code: "KG",
    name: i18n._(t({ id: "country_name.kyrgyzstan", message: "Kyrgyzstan" })),
    is_union_territory: false,
  },
  {
    code: "LA",
    name: i18n._(
      t({
        id: "country_name.lao_peoples_democratic_republic",
        message: "Lao People's Democratic Republic",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "LV",
    name: i18n._(t({ id: "country_name.latvia", message: "Latvia" })),
    is_union_territory: false,
  },
  {
    code: "LB",
    name: i18n._(t({ id: "country_name.lebanon", message: "Lebanon" })),
    is_union_territory: false,
  },
  {
    code: "LS",
    name: i18n._(t({ id: "country_name.lesotho", message: "Lesotho" })),
    is_union_territory: false,
  },
  {
    code: "LR",
    name: i18n._(t({ id: "country_name.liberia", message: "Liberia" })),
    is_union_territory: false,
  },
  {
    code: "LY",
    name: i18n._(t({ id: "country_name.libya", message: "Libya" })),
    is_union_territory: false,
  },
  {
    code: "LI",
    name: i18n._(
      t({ id: "country_name.liechtenstein", message: "Liechtenstein" })
    ),
    is_union_territory: false,
  },
  {
    code: "LT",
    name: i18n._(t({ id: "country_name.lithuania", message: "Lithuania" })),
    is_union_territory: false,
  },
  {
    code: "LU",
    name: i18n._(t({ id: "country_name.luxembourg", message: "Luxembourg" })),
    is_union_territory: false,
  },
  {
    code: "MO",
    name: i18n._(t({ id: "country_name.macao", message: "Macao" })),
    is_union_territory: true,
  },
  {
    code: "MK",
    name: i18n._(
      t({
        id: "country_name.macedonia_the_former_yugoslav_republic_of",
        message: "Macedonia (the former Yugoslav Republic of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "MG",
    name: i18n._(t({ id: "country_name.madagascar", message: "Madagascar" })),
    is_union_territory: false,
  },
  {
    code: "MW",
    name: i18n._(t({ id: "country_name.malawi", message: "Malawi" })),
    is_union_territory: false,
  },
  {
    code: "MY",
    name: i18n._(t({ id: "country_name.malaysia", message: "Malaysia" })),
    is_union_territory: false,
  },
  {
    code: "MV",
    name: i18n._(t({ id: "country_name.maldives", message: "Maldives" })),
    is_union_territory: false,
  },
  {
    code: "ML",
    name: i18n._(t({ id: "country_name.mali", message: "Mali" })),
    is_union_territory: false,
  },
  {
    code: "MT",
    name: i18n._(t({ id: "country_name.malta", message: "Malta" })),
    is_union_territory: false,
  },
  {
    code: "MH",
    name: i18n._(
      t({ id: "country_name.marshall_islands", message: "Marshall Islands" })
    ),
    is_union_territory: false,
  },
  {
    code: "MQ",
    name: i18n._(t({ id: "country_name.martinique", message: "Martinique" })),
    is_union_territory: true,
  },
  {
    code: "MR",
    name: i18n._(t({ id: "country_name.mauritania", message: "Mauritania" })),
    is_union_territory: false,
  },
  {
    code: "MU",
    name: i18n._(t({ id: "country_name.mauritius", message: "Mauritius" })),
    is_union_territory: false,
  },
  {
    code: "YT",
    name: i18n._(t({ id: "country_name.mayotte", message: "Mayotte" })),
    is_union_territory: true,
  },
  {
    code: "MX",
    name: i18n._(t({ id: "country_name.mexico", message: "Mexico" })),
    is_union_territory: false,
  },
  {
    code: "FM",
    name: i18n._(
      t({
        id: "country_name.micronesia_federated_states_of",
        message: "Micronesia (Federated States of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "MD",
    name: i18n._(
      t({
        id: "country_name.moldova_republic_of",
        message: "Moldova (Republic of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "MC",
    name: i18n._(t({ id: "country_name.monaco", message: "Monaco" })),
    is_union_territory: false,
  },
  {
    code: "MN",
    name: i18n._(t({ id: "country_name.mongolia", message: "Mongolia" })),
    is_union_territory: false,
  },
  {
    code: "ME",
    name: i18n._(t({ id: "country_name.montenegro", message: "Montenegro" })),
    is_union_territory: false,
  },
  {
    code: "MS",
    name: i18n._(t({ id: "country_name.montserrat", message: "Montserrat" })),
    is_union_territory: true,
  },
  {
    code: "MA",
    name: i18n._(t({ id: "country_name.morocco", message: "Morocco" })),
    is_union_territory: false,
  },
  {
    code: "MZ",
    name: i18n._(t({ id: "country_name.mozambique", message: "Mozambique" })),
    is_union_territory: false,
  },
  {
    code: "MM",
    name: i18n._(t({ id: "country_name.myanmar", message: "Myanmar" })),
    is_union_territory: false,
  },
  {
    code: "NA",
    name: i18n._(t({ id: "country_name.namibia", message: "Namibia" })),
    is_union_territory: false,
  },
  {
    code: "NR",
    name: i18n._(t({ id: "country_name.nauru", message: "Nauru" })),
    is_union_territory: false,
  },
  {
    code: "NP",
    name: i18n._(t({ id: "country_name.nepal", message: "Nepal" })),
    is_union_territory: false,
  },
  {
    code: "NL",
    name: i18n._(t({ id: "country_name.netherlands", message: "Netherlands" })),
    is_union_territory: false,
  },
  {
    code: "NC",
    name: i18n._(
      t({ id: "country_name.new_caledonia", message: "New Caledonia" })
    ),
    is_union_territory: true,
  },
  {
    code: "NZ",
    name: i18n._(t({ id: "country_name.new_zealand", message: "New Zealand" })),
    is_union_territory: false,
  },
  {
    code: "NI",
    name: i18n._(t({ id: "country_name.nicaragua", message: "Nicaragua" })),
    is_union_territory: false,
  },
  {
    code: "NE",
    name: i18n._(t({ id: "country_name.niger", message: "Niger" })),
    is_union_territory: false,
  },
  {
    code: "NG",
    name: i18n._(t({ id: "country_name.nigeria", message: "Nigeria" })),
    is_union_territory: false,
  },
  {
    code: "NU",
    name: i18n._(t({ id: "country_name.niue", message: "Niue" })),
    is_union_territory: true,
  },
  {
    code: "NF",
    name: i18n._(
      t({ id: "country_name.norfolk_island", message: "Norfolk Island" })
    ),
    is_union_territory: true,
  },
  {
    code: "KP",
    name: i18n._(
      t({
        id: "country_name.korea_democratic_peoples_republic_of",
        message: "Korea (Democratic People's Republic of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "MP",
    name: i18n._(
      t({
        id: "country_name.northern_mariana_islands",
        message: "Northern Mariana Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "NO",
    name: i18n._(t({ id: "country_name.norway", message: "Norway" })),
    is_union_territory: false,
  },
  {
    code: "OM",
    name: i18n._(t({ id: "country_name.oman", message: "Oman" })),
    is_union_territory: false,
  },
  {
    code: "PK",
    name: i18n._(t({ id: "country_name.pakistan", message: "Pakistan" })),
    is_union_territory: false,
  },
  {
    code: "PW",
    name: i18n._(t({ id: "country_name.palau", message: "Palau" })),
    is_union_territory: false,
  },
  {
    code: "PS",
    name: i18n._(
      t({
        id: "country_name.palestine_state_of",
        message: "Palestine, State of",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "PA",
    name: i18n._(t({ id: "country_name.panama", message: "Panama" })),
    is_union_territory: false,
  },
  {
    code: "PG",
    name: i18n._(
      t({ id: "country_name.papua_new_guinea", message: "Papua New Guinea" })
    ),
    is_union_territory: false,
  },
  {
    code: "PY",
    name: i18n._(t({ id: "country_name.paraguay", message: "Paraguay" })),
    is_union_territory: false,
  },
  {
    code: "PE",
    name: i18n._(t({ id: "country_name.peru", message: "Peru" })),
    is_union_territory: false,
  },
  {
    code: "PH",
    name: i18n._(t({ id: "country_name.philippines", message: "Philippines" })),
    is_union_territory: false,
  },
  {
    code: "PN",
    name: i18n._(t({ id: "country_name.pitcairn", message: "Pitcairn" })),
    is_union_territory: true,
  },
  {
    code: "PL",
    name: i18n._(t({ id: "country_name.poland", message: "Poland" })),
    is_union_territory: false,
  },
  {
    code: "PT",
    name: i18n._(t({ id: "country_name.portugal", message: "Portugal" })),
    is_union_territory: false,
  },
  {
    code: "PR",
    name: i18n._(t({ id: "country_name.puerto_rico", message: "Puerto Rico" })),
    is_union_territory: true,
  },
  {
    code: "QA",
    name: i18n._(t({ id: "country_name.qatar", message: "Qatar" })),
    is_union_territory: false,
  },
  {
    code: "XK",
    name: i18n._(
      t({
        id: "country_name.republic_of_kosovo",
        message: "Republic of Kosovo",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "RE",
    name: i18n._(t({ id: "country_name.reunion", message: "Réunion" })),
    is_union_territory: true,
  },
  {
    code: "RO",
    name: i18n._(t({ id: "country_name.romania", message: "Romania" })),
    is_union_territory: false,
  },
  {
    code: "RU",
    name: i18n._(
      t({
        id: "country_name.russian_federation",
        message: "Russian Federation",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "RW",
    name: i18n._(t({ id: "country_name.rwanda", message: "Rwanda" })),
    is_union_territory: false,
  },
  {
    code: "BL",
    name: i18n._(
      t({ id: "country_name.saint_barthelemy", message: "Saint Barthélemy" })
    ),
    is_union_territory: true,
  },
  {
    code: "SH",
    name: i18n._(
      t({
        id: "country_name.saint_helena_ascension_and_tristan_da_cunha",
        message: "Saint Helena, Ascension and Tristan da Cunha",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "KN",
    name: i18n._(
      t({
        id: "country_name.saint_kitts_and_nevis",
        message: "Saint Kitts and Nevis",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "LC",
    name: i18n._(t({ id: "country_name.saint_lucia", message: "Saint Lucia" })),
    is_union_territory: false,
  },
  {
    code: "MF",
    name: i18n._(
      t({
        id: "country_name.saint_martin_french_part",
        message: "Saint Martin (French part)",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "PM",
    name: i18n._(
      t({
        id: "country_name.saint_pierre_and_miquelon",
        message: "Saint Pierre and Miquelon",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "VC",
    name: i18n._(
      t({
        id: "country_name.saint_vincent_and_the_grenadines",
        message: "Saint Vincent and the Grenadines",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "WS",
    name: i18n._(t({ id: "country_name.samoa", message: "Samoa" })),
    is_union_territory: false,
  },
  {
    code: "SM",
    name: i18n._(t({ id: "country_name.san_marino", message: "San Marino" })),
    is_union_territory: false,
  },
  {
    code: "ST",
    name: i18n._(
      t({
        id: "country_name.sao_tome_and_principe",
        message: "Sao Tome and Principe",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "SA",
    name: i18n._(
      t({ id: "country_name.saudi_arabia", message: "Saudi Arabia" })
    ),
    is_union_territory: false,
  },
  {
    code: "SN",
    name: i18n._(t({ id: "country_name.senegal", message: "Senegal" })),
    is_union_territory: false,
  },
  {
    code: "RS",
    name: i18n._(t({ id: "country_name.serbia", message: "Serbia" })),
    is_union_territory: false,
  },
  {
    code: "SC",
    name: i18n._(t({ id: "country_name.seychelles", message: "Seychelles" })),
    is_union_territory: false,
  },
  {
    code: "SL",
    name: i18n._(
      t({ id: "country_name.sierra_leone", message: "Sierra Leone" })
    ),
    is_union_territory: false,
  },
  {
    code: "SG",
    name: i18n._(t({ id: "country_name.singapore", message: "Singapore" })),
    is_union_territory: false,
  },
  {
    code: "SX",
    name: i18n._(
      t({
        id: "country_name.sint_maarten_dutch_part",
        message: "Sint Maarten (Dutch part)",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "SK",
    name: i18n._(t({ id: "country_name.slovakia", message: "Slovakia" })),
    is_union_territory: false,
  },
  {
    code: "SI",
    name: i18n._(t({ id: "country_name.slovenia", message: "Slovenia" })),
    is_union_territory: false,
  },
  {
    code: "SB",
    name: i18n._(
      t({ id: "country_name.solomon_islands", message: "Solomon Islands" })
    ),
    is_union_territory: false,
  },
  {
    code: "SO",
    name: i18n._(t({ id: "country_name.somalia", message: "Somalia" })),
    is_union_territory: false,
  },
  {
    code: "ZA",
    name: i18n._(
      t({ id: "country_name.south_africa", message: "South Africa" })
    ),
    is_union_territory: false,
  },
  {
    code: "GS",
    name: i18n._(
      t({
        id: "country_name.south_georgia_and_the_south_sandwich_islands",
        message: "South Georgia and the South Sandwich Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "KR",
    name: i18n._(
      t({
        id: "country_name.korea_republic_of",
        message: "Korea (Republic of)",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "SS",
    name: i18n._(t({ id: "country_name.south_sudan", message: "South Sudan" })),
    is_union_territory: false,
  },
  {
    code: "ES",
    name: i18n._(t({ id: "country_name.spain", message: "Spain" })),
    is_union_territory: false,
  },
  {
    code: "LK",
    name: i18n._(t({ id: "country_name.sri_lanka", message: "Sri Lanka" })),
    is_union_territory: false,
  },
  {
    code: "SD",
    name: i18n._(t({ id: "country_name.sudan", message: "Sudan" })),
    is_union_territory: false,
  },
  {
    code: "SR",
    name: i18n._(t({ id: "country_name.suriname", message: "Suriname" })),
    is_union_territory: false,
  },
  {
    code: "SJ",
    name: i18n._(
      t({
        id: "country_name.svalbard_and_jan_mayen",
        message: "Svalbard and Jan Mayen",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "SZ",
    name: i18n._(t({ id: "country_name.swaziland", message: "Swaziland" })),
    is_union_territory: false,
  },
  {
    code: "SE",
    name: i18n._(t({ id: "country_name.sweden", message: "Sweden" })),
    is_union_territory: false,
  },
  {
    code: "CH",
    name: i18n._(t({ id: "country_name.switzerland", message: "Switzerland" })),
    is_union_territory: false,
  },
  {
    code: "SY",
    name: i18n._(
      t({
        id: "country_name.syrian_arab_republic",
        message: "Syrian Arab Republic",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "TW",
    name: i18n._(t({ id: "country_name.taiwan", message: "Taiwan" })),
    is_union_territory: false,
  },
  {
    code: "TJ",
    name: i18n._(t({ id: "country_name.tajikistan", message: "Tajikistan" })),
    is_union_territory: false,
  },
  {
    code: "TZ",
    name: i18n._(
      t({
        id: "country_name.tanzania_united_republic_of",
        message: "Tanzania, United Republic of",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "TH",
    name: i18n._(t({ id: "country_name.thailand", message: "Thailand" })),
    is_union_territory: false,
  },
  {
    code: "TL",
    name: i18n._(t({ id: "country_name.timorleste", message: "Timor-Leste" })),
    is_union_territory: false,
  },
  {
    code: "TG",
    name: i18n._(t({ id: "country_name.togo", message: "Togo" })),
    is_union_territory: false,
  },
  {
    code: "TK",
    name: i18n._(t({ id: "country_name.tokelau", message: "Tokelau" })),
    is_union_territory: true,
  },
  {
    code: "TO",
    name: i18n._(t({ id: "country_name.tonga", message: "Tonga" })),
    is_union_territory: false,
  },
  {
    code: "TT",
    name: i18n._(
      t({
        id: "country_name.trinidad_and_tobago",
        message: "Trinidad and Tobago",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "TN",
    name: i18n._(t({ id: "country_name.tunisia", message: "Tunisia" })),
    is_union_territory: false,
  },
  {
    code: "TR",
    name: i18n._(t({ id: "country_name.turkey", message: "Turkey" })),
    is_union_territory: false,
  },
  {
    code: "TM",
    name: i18n._(
      t({ id: "country_name.turkmenistan", message: "Turkmenistan" })
    ),
    is_union_territory: false,
  },
  {
    code: "TC",
    name: i18n._(
      t({
        id: "country_name.turks_and_caicos_islands",
        message: "Turks and Caicos Islands",
      })
    ),
    is_union_territory: true,
  },
  {
    code: "TV",
    name: i18n._(t({ id: "country_name.tuvalu", message: "Tuvalu" })),
    is_union_territory: false,
  },
  {
    code: "UG",
    name: i18n._(t({ id: "country_name.uganda", message: "Uganda" })),
    is_union_territory: false,
  },
  {
    code: "UA",
    name: i18n._(t({ id: "country_name.ukraine", message: "Ukraine" })),
    is_union_territory: false,
  },
  {
    code: "AE",
    name: i18n._(
      t({
        id: "country_name.united_arab_emirates",
        message: "United Arab Emirates",
      })
    ),
    is_union_territory: false,
  },
  {
    code: "US",
    name: i18n._(
      t({ id: "country_name.united_states", message: "United States" })
    ),
    is_union_territory: false,
  },
  {
    code: "UY",
    name: i18n._(t({ id: "country_name.uruguay", message: "Uruguay" })),
    is_union_territory: false,
  },
  {
    code: "UZ",
    name: i18n._(t({ id: "country_name.uzbekistan", message: "Uzbekistan" })),
    is_union_territory: false,
  },
  {
    code: "VU",
    name: i18n._(t({ id: "country_name.vanuatu", message: "Vanuatu" })),
    is_union_territory: false,
  },
  {
    code: "VE",
    name: i18n._(t({ id: "country_name.venezuela", message: "Venezuela" })),
    is_union_territory: false,
  },
  {
    code: "VN",
    name: i18n._(t({ id: "country_name.viet_nam", message: "Viet Nam" })),
    is_union_territory: false,
  },
  {
    code: "WF",
    name: i18n._(
      t({ id: "country_name.wallis_and_futuna", message: "Wallis and Futuna" })
    ),
    is_union_territory: true,
  },
  {
    code: "EH",
    name: i18n._(
      t({ id: "country_name.western_sahara", message: "Western Sahara" })
    ),
    is_union_territory: false,
  },
  {
    code: "YE",
    name: i18n._(t({ id: "country_name.yemen", message: "Yemen" })),
    is_union_territory: false,
  },
  {
    code: "ZM",
    name: i18n._(t({ id: "country_name.zambia", message: "Zambia" })),
    is_union_territory: false,
  },
  {
    code: "ZW",
    name: i18n._(t({ id: "country_name.zimbabwe", message: "Zimbabwe" })),
    is_union_territory: false,
  },
];

export const COUNTRY_CODES_WITHOUT_OVERSEAS_TERRITORIES = (
  i18n: I18n
): {
  code: string;
  name: string;
  is_union_territory: boolean;
}[] =>
  COUNTRY_CODES(i18n)
    .filter((country) => !country.is_union_territory)
    .sort((a, b) => (a.name < b.name ? -1 : 1));
