import { GoogleAnalytics4Script, GA4Commands } from "./GoogleAnalytics4";
import { GA4Events } from "./events";

export { GoogleAnalytics4Script, GA4Events };

export const sendGA4Event = (event: GA4Events | string, parameters: object) => {
  if (window.gtag) {
    return window.gtag(GA4Commands.Event, event, parameters);
  }
};
