/* eslint-disable */
export const loadDriftScript = (writeKey) => {
  "use strict";

  // when SegmentAbs is executed, window does not exist yet
  if (writeKey) {
    !(function () {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on",
            "addSourceMiddleware",
            "addIntegrationMiddleware",
            "setAnonymousId",
            "addDestinationMiddleware",
          ];
          analytics.factory = function (e) {
            return function () {
              if (window.analytics.initialized)
                return window.analytics[e].apply(window.analytics, arguments);
              var i = Array.prototype.slice.call(arguments);
              i.unshift(e);
              analytics.push(i);
              return analytics;
            };
          };
          for (var i = 0; i < analytics.methods.length; i++) {
            var key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
          }
          analytics.load = function (key, i) {
            var t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.src =
              "https://cdn.segment.com/analytics.js/v1/" +
              key +
              "/analytics.min.js";
            var n = document.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = i;
          };
          analytics._writeKey = writeKey;
          analytics.SNIPPET_VERSION = "4.16.1";
          analytics.load(writeKey);
          analytics.page();
        }
    })();
  }

  !(function () {
    var t = (window.driftt = window.drift = window.driftt || []);
    if (!t.init) {
      if (t.invoked) return;
      t.invoked = true;
      t.methods = [
        "identify",
        "config",
        "track",
        "reset",
        "debug",
        "show",
        "ping",
        "page",
        "hide",
        "off",
        "on",
      ];
      t.factory = function (e) {
        return function () {
          var n = Array.prototype.slice.call(arguments);
          n.unshift(e);
          t.push(n);
          return t;
        };
      };
      t.methods.forEach(function (e) {
        t[e] = t.factory(e);
      });
      t.load = function (t) {
        var e = 3e5;
        var n = Math.ceil(new Date() / e) * e;
        var o = document.createElement("script");
        o.type = "text/javascript";
        o.async = true;
        o.crossorigin = "anonymous";
        o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
        var i = document.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(o, i);
      };
    }
  })();
  drift.SNIPPET_VERSION = "0.3.1";
  drift.on("ready", function (api) {
    // show widget again if user hide it and new message from team member is sent.
    drift.on("message", function (e) {
      if (e.message) {
        api.widget.show();
      }
    });
  });

  const loadDriftAnalytics = (writeKey) => {
    if (writeKey) {
      analytics.ready(function () {
        window.drift.on("ready", function (api, payload) {
          window.drift.on("conversation:firstInteraction", function (data) {
            analytics.track(
              "Drift Source Event: conversation:firstInteraction",
              {
                conversationId: data.conversationId,
                playbookId: data.playbookId,
                campaignId: data.campaignId,
                contactId: data.authorId,
              }
            );
          });

          window.drift.on("startConversation", function (data) {
            analytics.track("Drift Source Event: startConversation", {
              conversationId: data.conversationId,
              inboxId: data.inboxId,
              contactId: data.endUserId,
              email: data.endUserEmail,
            });
          });

          window.drift.on("conversation:selected", function (data) {
            analytics.track("Drift Source Event: conversation:selected", {
              driftConversationId: data.conversationId,
            });
          });

          window.drift.on("scheduling:requestMeeting", function (data) {
            analytics.track("Drift Source Event: scheduling:requestMeeting", {
              driftTeamMemberId: data.teamMember.id,
              driftTeamMemberName: data.teamMember.name,
            });
          });

          window.drift.on("conversation:playbookFired", function (data) {
            analytics.track("Drift Source Event: conversation:playbookFired", {
              playbookId: data.playbookId,
              campaignId: data.campaignId,
            });
          });

          window.drift.on("conversation:playbookClicked", function (data) {
            analytics.track(
              "Drift Source Event: conversation:playbookClicked",
              {
                playbookId: data.playbookId,
                campaignId: data.campaignId,
              }
            );
          });

          window.drift.on("conversation:playbookDismissed", function (data) {
            analytics.track(
              "Drift Source Event: conversation:playbookDismissed",
              {
                playbookId: data.playbookId,
                campaignId: data.campaignId,
              }
            );
          });

          window.drift.on("campaign:open", function (data) {
            analytics.track("Drift Source Event: campaign:open", {
              campaignId: data.campaignId,
            });
          });

          window.drift.on("campaign:dismiss", function (data) {
            analytics.track("Drift Source Event: campaign:dismiss", {
              campaignId: data.campaignId,
            });
          });

          window.drift.on("campaign:click", function (data) {
            analytics.track("Drift Source Event: campaign:click", {
              campaignId: data.campaignId,
            });
          });

          window.drift.on("campaign:submit", function (data) {
            analytics.track("Drift Source Event: campaign:submit", {
              campaignId: data.campaignId,
            });
          });

          window.drift.on("sliderMessage:close", function (data) {
            analytics.track("Drift Source Event: sliderMessage:close", {
              campaignId: data.campaignId,
              playbookId: data.playbookId,
            });
          });
        });
      });
    }
  };
  loadDriftAnalytics(writeKey);
};

export const hideDrift = () => {
  const snipet = document.getElementById("drift-frame-controller");
  if (snipet) {
    snipet.style.display = "none";
  }
};

export const showDrift = () => {
  const snipet = document.getElementById("drift-frame-controller");
  if (snipet) {
    snipet.style.display = "block";
  }
};
