import Router from "next/router";
import { useAdminAccessTokenCreate } from "@gocardless/api/staff/admin-access-token";
import { useUserShowSelf } from "@gocardless/api/staff/user";
import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";
import { useClearSWR } from "@gocardless/api/utils/use-clear-cache";

import { useOrganisationIdFromResponseHeader } from "../api";

import { useAccessToken } from "./session";

export const useImpersonateOrganisation = () => {
  const clearCache = useClearSWR();
  const organisationIdsToImpersonate = useOrganisationIdFromResponseHeader();
  const [accessToken, setAccessToken] = useAccessToken();
  const { data: staffMember } = useUserShowSelf();
  const [submit] = useAdminAccessTokenCreate({
    onSuccess: (response) => {
      setAccessToken(response);
      //  as we have a new access token we clear the
      //  cache so that we we re-fetch all requests.
      clearCache();
      // Force page to reload with new access token.
      Router.reload();
    },
  });
  const accessTokenOrganisationId = accessToken?.links?.organisation;
  const staffMemberOrganisationId = staffMember?.users?.links?.organisation;
  const organisationIdToImpersonate =
    organisationIdsToImpersonate.find(
      (id) =>
        ![staffMemberOrganisationId, accessTokenOrganisationId].includes(id)
    ) || "";
  const isImpersonating = Boolean(
    staffMember && staffMemberOrganisationId !== accessTokenOrganisationId
  );
  const shouldFetchImpersonatingOrganisation = Boolean(
    staffMember && !isImpersonating && organisationIdToImpersonate
  );
  const { data: organisationToImpersonate } = useOrganisationSelf(
    shouldFetchImpersonatingOrganisation ? organisationIdToImpersonate : null
  );
  const isLoading =
    !staffMember ||
    (shouldFetchImpersonatingOrganisation ? !organisationToImpersonate : false);

  const impersonateOrganisation = async () => {
    if (organisationIdToImpersonate) {
      await submit({ links: { organisation: organisationIdToImpersonate } });
    }
  };
  const stopImpersonating = async () => {
    if (isImpersonating && staffMemberOrganisationId) {
      await submit({ links: { organisation: staffMemberOrganisationId } });
    }
  };

  return {
    isLoading,
    impersonateOrganisation,
    stopImpersonating,
    isImpersonating,
    organisationToImpersonate,
  };
};
