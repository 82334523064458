import { useRouter } from "next/router";
import { useEffect } from "react";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { TrackingEvent } from "src/common/trackingEvents";

import { useSegment } from "./useSegment";

export const useSendPageViewEvent = (
  eventName: TrackingEvent,
  additionalAttributes: {} = {}
) => {
  const router = useRouter();
  const { sendEvent } = useSegment();
  const { status } = useVerificationStatus();

  useEffect(() => {
    sendEvent(eventName, {
      page: router.pathname,
      verification_status: status,
      ...additionalAttributes,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
