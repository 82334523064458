import { IncomingHttpHeaders } from "http";

import kebabCase from "lodash/kebabCase";

export const getHeaderFromResponse = (response: Response, header: string) =>
  response.headers.get(header) ||
  // Cypress sets the headers to kebab-case, so we fall-back to a kebab-case header.
  response.headers.get(kebabCase(header));

// This works with our Cloudflare CDN as it provides the "cf-ipcountry" header.
// Best used with getServerSideProps.
export const countryCodeFromRequestHeaders = (headers: IncomingHttpHeaders) => {
  const cfCountry = headers["cf-ipcountry"];
  const requestCountry =
    !!cfCountry && cfCountry !== "XX" ? cfCountry : headers["client-region"];
  let code: string | undefined;
  if (requestCountry) {
    code =
      typeof requestCountry === "string" ? requestCountry : requestCountry[0];
  }

  return code || "XX";
};
