import { useEffect, useState } from "react";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import { setupI18n } from "@lingui/core";

import {
  DEFAULT_LOCALE,
  I18nContext,
  UserLocale,
  getCatalog,
} from "../../technical-integrations/i18n";

export { useI18n } from "../../technical-integrations/i18n";

const loadedLocale: Set<UserLocale> = new Set([]);

export const customI18n = setupI18n({
  messages: {},
});

const navShort: Record<string, UserLocale> = {
  en: UserLocale.EN_GB,
  de: UserLocale.DE_DE,
  es: UserLocale.ES_ES,
  fr: UserLocale.FR_FR,
};

const navLong: Record<string, UserLocale> = {
  [UserLocale.EN_GB]: UserLocale.EN_GB,
  [UserLocale.EN_US]: UserLocale.EN_US,
  [UserLocale.DE_DE]: UserLocale.DE_DE,
  [UserLocale.ES_ES]: UserLocale.ES_ES,
  [UserLocale.FR_FR]: UserLocale.FR_FR,
};

export const useI18nLoader = (locale: UserLocale) => {
  useEffect(() => {
    (async () => {
      if (!loadedLocale.has(locale)) {
        customI18n.load(locale, await getCatalog(locale));
        loadedLocale.add(locale);
      }
      customI18n.activate(locale);
    })();
  }, [locale]);
};

const I18nProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    const navigatorLang = window.navigator.language as UserLocale;
    const navLang = navShort[navigatorLang] || navLong[navigatorLang];

    if (navLang) {
      setLocale(navLang);
    }
  }, []);

  useI18nLoader(locale);

  // Since activating i18n is async you may see the log line:
  // "I18nProvider did not render. A call to i18n.activate still needs to happen or forceRenderOnLocaleChange must be set to false."

  return (
    <I18nContext.Provider value={[locale, setLocale]}>
      <LinguiI18nProvider i18n={customI18n}>
        {props.children}
      </LinguiI18nProvider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
