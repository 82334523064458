import { AccessTokenCreateRequestBodyScope } from "@gocardless/api/dashboard/types";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useEffect } from "react";
import { Route, routerPush } from "src/common/routing";
import { getItem } from "src/common/local-storage/local-storage";

interface UseIdentityCheck {
  enforcingIdentityCheck: boolean;
}

export enum UserVerificationStatus {
  NOT_STARTED = "not_started",
  ACTION_REQUIRED = "action_required",
  IN_REVIEW = "in_review",
  SUCCESSFUL = "successful",
  UNSUCCESSFUL = "unsuccessful",
}

export const IDENTITY_CHECK_STORAGE_KEY = "gc.identity-check-status";

export const useIdentityCheck = (
  enforceIdentityCheck = true
): UseIdentityCheck => {
  const { data } = useUserShowSelf();

  const user = data?.users || {};
  const { verification_status, primary, scope } = user;
  const skipCheck = getItem(IDENTITY_CHECK_STORAGE_KEY)?.skip;

  const enforcingIdentityCheck =
    enforceIdentityCheck &&
    !skipCheck &&
    !!verification_status &&
    (verification_status === UserVerificationStatus.NOT_STARTED ||
      verification_status === UserVerificationStatus.ACTION_REQUIRED) &&
    !primary &&
    scope !== AccessTokenCreateRequestBodyScope.ReadOnly;

  useEffect(() => {
    if (enforcingIdentityCheck) {
      const route: Route =
        verification_status === UserVerificationStatus.NOT_STARTED
          ? Route.SetupUser
          : Route.SetupUserVerify;
      routerPush({ route });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enforcingIdentityCheck]);

  return {
    enforcingIdentityCheck,
  };
};
