import { Box, ColorPreset, Text, TextAlign } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { ExportsStatus } from "@gocardless/api/dashboard/types";

import { ExportStatus } from "./ExportsCentreExportRowItem";
import ExportsError from "./ExportsError";
import { ExportsCentreBodyProps } from "./ExportsCentreBody";

interface ExportsCentreBodyHeaderProgressProps {
  completed: number | string;
  total: number | string;
}

export const ExportsCenterBodyHeader: React.FC<ExportsCentreBodyProps> = (
  props
) => {
  const { completedExports, pendingExports, failedExports, isCentreOpen } =
    props;

  const { data: user } = useUserShowSelf();
  const email = user?.users?.email || "";

  const hasFailedExports = failedExports.length > 0;
  const hasMultipleFailedExports = failedExports.length > 1;

  const showSuccessHeader =
    !hasMultipleFailedExports && !(!isCentreOpen && hasFailedExports);

  const showFailedHeader =
    (!isCentreOpen && hasFailedExports) ||
    (isCentreOpen && hasMultipleFailedExports);

  const hasPendingExports = pendingExports.length > 0;

  if (showSuccessHeader && hasPendingExports) {
    return (
      <Box layout="flex" gutterV={0.5}>
        <ExportsCentreBodyHeaderMessage>
          <Trans id="exports.centre.email">
            We will email you a link to the export when it’s ready.
          </Trans>
        </ExportsCentreBodyHeaderMessage>
        {!isCentreOpen && (
          <ExportsCentreBodyHeaderProgress
            completed={completedExports.length}
            total={pendingExports.length + completedExports.length}
          />
        )}
      </Box>
    );
  }

  if (showSuccessHeader && !hasPendingExports) {
    return (
      <Box layout="flex" gutterV={0.5}>
        <ExportsCentreBodyHeaderMessage>
          <Trans id="exports.centre.finished">
            A link to the export has been emailed to
          </Trans>{" "}
          {email}
        </ExportsCentreBodyHeaderMessage>
        {!isCentreOpen && <ExportStatus status={ExportsStatus.Successful} />}
      </Box>
    );
  }

  if (showFailedHeader) {
    return (
      <Box gutterV={0.5} maxWidth={400}>
        <ExportsError />
      </Box>
    );
  }
  return null;
};

export const ExportsCentreBodyHeaderProgress: React.FC<
  ExportsCentreBodyHeaderProgressProps
> = (props) => {
  const { completed, total } = props;
  return (
    <Text
      size={2}
      color={ColorPreset.TextOnLight_02}
      textAlign={TextAlign.Right}
    >
      <Trans id="exports.centre.header.progress">
        {completed} out of {total} complete
      </Trans>
    </Text>
  );
};

export const ExportsCentreBodyHeaderMessage: React.FC<{
  children: React.ReactNode;
}> = (props) => (
  <Text size={2} color={ColorPreset.TextOnLight_02}>
    {props.children}
  </Text>
);
