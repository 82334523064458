import {
  useCreditorSelf,
  useCreditorList,
} from "@gocardless/api/dashboard/creditor";
import { CreditorSelfRequestBody } from "@gocardless/api/dashboard/types";

import { useOrganisation } from "./organisation";

// useDefaultCreditor is designed for organisations with a single creditor.
// It is used where multi-creditor organisations are not supported.
// In all other cases usePrimaryCreditor should be preferred.
export const useDefaultCreditor = () => {
  const organisation = useOrganisation();
  /*
   * If the organisation is a multi-creditor organisation
   * the linked default_creditor will be `null`
   */
  const defaultCreditorId = organisation?.links?.default_creditor;

  const { data: creditor } = useCreditorSelf(defaultCreditorId || null);
  if (organisation && !defaultCreditorId) return null;

  return creditor?.creditors;
};

export const useGetPrimaryCreditorResponse = (
  queryParams?: CreditorSelfRequestBody,
  shouldMakeRequest = true
) => {
  const organisation = useOrganisation();
  const primaryCreditorId = organisation?.links?.primary_creditor;

  const response = useCreditorSelf(
    primaryCreditorId || null,
    queryParams,
    shouldMakeRequest
  );

  return response;
};

export const usePrimaryCreditor = (queryParams?: CreditorSelfRequestBody) => {
  const { data: creditor } = useGetPrimaryCreditorResponse(queryParams);

  return creditor?.creditors;
};

/*
 * useRelevantCreditor is used when we want to enable features for Embed merchants
 * and not legacy multi-creditor organisations.
 *
 * There are instances where we cannot assume that usePrimaryCreditor will be correct
 * so we are more explicit about when we want to enable functionality for Embed merchants
 */
export const useRelevantCreditor = () => {
  const organisation = useOrganisation();

  let creditorId = organisation?.links?.default_creditor;

  if (organisation?.links?.payment_provider) {
    creditorId = organisation?.links?.primary_creditor;
  }

  const { data: creditor } = useCreditorSelf(creditorId || null);
  if (organisation && !creditorId) return null;

  return creditor?.creditors;
};

/*
 * useOrganisationCreditorsByProduct is used when we want to return the list of
 * creditors alongside the primary creditor and whether we are an embed
 * organisation
 *
 * When we are an Embed organisation, we filter out the primary_creditor as it
 * represents the Embed-enabled organisation and will not be involved in any
 * flow of funds activity.
 *
 * Note: This will make a request with as little pagination as possible so the
 * response will be potentially larger than you may want
 */
export const useOrganisationCreditorsByProduct = (
  creditorFields?: string[]
) => {
  const organisation = useOrganisation();
  const primaryCreditor = organisation?.links?.primary_creditor;
  const isEmbedOrg = !!organisation?.links?.payment_provider;

  const { data: creditorsResponse } = useCreditorList({
    fields: creditorFields?.join(","),
  });
  let creditors = creditorsResponse?.creditors;

  if (!creditors) {
    return { creditors: [], primaryCreditorId: primaryCreditor, isEmbedOrg };
  }

  // We filter out the primary_creditor if the org is an Embed merchant
  // We need to scope it in a conditional otherwise we end up filtering out the only creditor of a standard merchant
  if (isEmbedOrg) {
    creditors = creditors.filter((creditor) => creditor.id !== primaryCreditor);
  }

  return {
    creditors: creditors,
    primaryCreditorId: primaryCreditor,
    isEmbedOrg,
  };
};
