export const GC_ORGANISATION = "gc.organisation.id";

export enum CookieStorage {
  WebAnonymousID = "gc_web_amp_anonymous_id",
  PreSignupOptimizelyID = "gc_web_experiment_visitor_id", // same cookie used on website
  PricingOptimizelyID = "gc_web_pricing_experiment_visitor_id",
  ImpactClickID = "impact",
  GACookieClientID = "_ga",
  AWinClickID = "awin",
  DriftID = "drift_eid",
}

export const cookieDomain: Record<string, string> = {
  staging: ".gocardless-staging.io",
  production: ".gocardless.com",
};
