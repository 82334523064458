import { ReactNode } from "react";
export { UserLocale } from "../../technical-integrations/i18n";
export { default as I18nProvider, useI18n } from "./i18n";
export { default as UserLocaleWrapper } from "./UserLocaleWrapper";

/*
 * These methods are so that you can contribute code and not be
 * be blocked on translation. You can use these in places of
 * `<Trans/>` and `i18Mark` so long as your content is guarded
 * and you follow up with translation in another pull request.
 */
export const ToTranslate: React.FunctionComponent<{
  id?: string;
  children: React.ReactNode;
}> = ({ id, children }) => (children as React.ReactElement) || id || "";

export const ToPlural: React.FC<{
  value: number | string;
  other: string;
  [key: string]: ReactNode;
}> = ({ value, other, ...props }) => {
  const customPluralisation = props[`_${value}`];

  if (customPluralisation) return <>{customPluralisation}</>;

  return <>{other.replace("#", String(value))}</>;
};

export const i18nMarkTranslate = (str: string): string => str;
