import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useUserShowSelf as useStaffUserShowSelf } from "@gocardless/api/staff/user";
import { AccessTokenCreateRequestBodyScope } from "@gocardless/api/dashboard/types";
import { useOrganisation } from "src/queries/organisation";

export const useUserDashboardPreference = () => {
  const { data } = useUserShowSelf();
  if (data === undefined) {
    return undefined;
  }
  if (data.users?.dashboard_preferences === undefined) {
    return null;
  }
  return data.users.dashboard_preferences;
};

export const useUser = () => {
  const { data: userData } = useUserShowSelf();

  return userData?.users;
};

export const useIsAdmin = () => {
  const user = useUser();
  return user?.scope === AccessTokenCreateRequestBodyScope.Admin;
};

export const useIsReadOnly = () => {
  const user = useUser();
  return user?.scope === AccessTokenCreateRequestBodyScope.ReadOnly;
};

export const useIsGCAdmin = () => {
  const { data: userData } = useStaffUserShowSelf();
  const user = userData?.users;

  return user?.is_admin || false;
};

export const useIsImpersonation = () => {
  const organisation = useOrganisation();
  const isGCAdmin = useIsGCAdmin();
  const { data: userData } = useUserShowSelf();

  return (
    isGCAdmin &&
    organisation &&
    organisation?.id !== userData?.users?.links?.organisation
  );
};
