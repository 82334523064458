import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";

import { useAccessToken } from "../common/authorisation";

/*
 * We fetch the organisation by the the link
 * in the access token to support staff user impersonating
 * merchant.
 */
export const useOrganisation = () => {
  const [accessToken] = useAccessToken();

  const { data: organisation } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );

  return organisation?.organisations;
};

export const useOrganisationId = (): string | undefined => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return organisation.id;
};

/*
 * Consider if you want to use `usePrimaryCreditorId` as
 * `defaultCreditor` can sometimes return `null`.
 * Not all organisations have a default creditor such as Payment Providers or
 * or multi-creditor merchants, but they will have a primary creditor.
 */
export const useDefaultCreditorId = () => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return organisation.links?.default_creditor;
};

export const usePrimaryCreditorId = () => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return organisation.links?.primary_creditor;
};

export const useDefaultCountryCode = () => {
  const organisation = useOrganisation();
  return organisation?.signup_country_code ?? undefined;
};

export const useDefaultCurrency = () => {
  const organisation = useOrganisation();
  return organisation?.domestic_currency ?? undefined;
};
