import { useEffect } from "react";

/* eslint-disable no-console */
export interface LogAppVersionProps {}

export const LogAppVersion: React.FC<LogAppVersionProps> = () => {
  useEffect(() => {
    const nodes = document.querySelectorAll('meta[name="app-version"]');
    const buildId = nodes[0]?.getAttribute("content");
    if (nodes.length === 1) {
      console.log(
        `%c🟢 Build ID: ${buildId}`,
        "font-weight: bold; color: green;"
      );
    } else if (nodes.length > 1) {
      console.error(
        'LogAppVersion: Found multiple <meta name="app-version" content="..." /> tags'
      );
    } else {
      console.error(
        'LogAppVersion: Did not find any <meta name="app-version" content="..." /> tags'
      );
    }
  }, []);
  return null;
};
