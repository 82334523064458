import { GC_SUPPORT_ORIGIN } from "../constants";

/**
 * A class that extends the native URL interface and provides methods for manipulating Zendesk URLs.
 */
export class ZendeskUrl extends URL {
  constructor(formId: string, locale?: string) {
    super(ZendeskUrl.getLocalizedOrDefaultPathname(locale), GC_SUPPORT_ORIGIN);
    this.searchParams.set("ticket_form_id", formId);
  }

  /**
   * Retrieves the localized or default pathname based on the provided locale.
   *
   * @param {string} locale - the locale to be used for localization
   * @return {string} the localized or default pathname
   */
  static getLocalizedOrDefaultPathname(locale = ""): string {
    let maybeLocale = "";

    try {
      new Intl.Locale(locale);
      maybeLocale = locale?.toLowerCase();
    } catch {
      // ignore
    }

    return ["hc", maybeLocale, "requests", "new"].filter(Boolean).join("/");
  }

  /**
   * Sets the URL search parameters to the URL.
   *
   * @param {URLSearchParams} params - the parameters to set to the URL
   * @return {ZendeskUrl} the current ZendeskUrl instance
   */
  withSearchParams(params: URLSearchParams): ZendeskUrl {
    params.forEach((value, key) => {
      this.searchParams.set(key, value);
    });

    return this;
  }

  /**
   * Sets `tf_anonymous_requester_email` in the URL search parameters.
   *
   * @param {string} email - the email to set
   * @return {ZendeskUrl} the current ZendeskUrl instance
   */
  withEmail(email = ""): ZendeskUrl {
    if (email.length) {
      this.searchParams.set("tf_anonymous_requester_email", email);
    }
    return this;
  }
}
