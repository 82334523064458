import { flatten } from "lodash";
import { Box, ColorPreset, Separator } from "@gocardless/flux-react";

import ExportsCentreExportRowItem from "./ExportsCentreExportRowItem";
import { onExportUpdateFunc } from "./ExportCentreConnect";
import { ExportID } from "./ExportsCentre";

interface ExportsCentreBodyItemsProps {
  completedExports: Array<ExportID>;
  isCentreOpen: boolean;
  failedExports: Array<ExportID>;
  pendingExports: Array<ExportID>;
  totalFailures?: number;
  onExportUpdate: onExportUpdateFunc;
}

export const ExportsCentreBodyItems: React.FC<ExportsCentreBodyItemsProps> = (
  props
) => {
  const {
    completedExports,
    pendingExports,
    failedExports,
    onExportUpdate,
    isCentreOpen,
  } = props;

  const failedExportItems = failedExports.map((exportId: string) => (
    <ExportsCentreExportRowItem
      key={exportId}
      id={exportId}
      onExportUpdate={onExportUpdate}
      isCentreOpen={isCentreOpen}
    />
  ));
  const pendingExportItems = pendingExports.map((exportId: string) => (
    <ExportsCentreExportRowItem
      key={exportId}
      id={exportId}
      onExportUpdate={onExportUpdate}
      isCentreOpen={isCentreOpen}
    />
  ));
  const completedExportItems = completedExports.map((exportId: string) => (
    <ExportsCentreExportRowItem
      key={exportId}
      id={exportId}
      onExportUpdate={onExportUpdate}
      isCentreOpen={isCentreOpen}
    />
  ));

  const combined = [
    failedExportItems,
    pendingExportItems,
    completedExportItems,
  ];

  const merged = flatten(combined) as JSX.Element[];

  return (
    <Box>
      {merged.map((e, idx) => (
        <Box key={idx}>
          {e}
          {isCentreOpen && idx !== merged.length - 1 && (
            <Separator color={ColorPreset.BorderOnLight_04} />
          )}
        </Box>
      ))}
    </Box>
  );
};
