import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { usePrimaryCreditor } from "src/queries/creditor";

interface UseVerificationStatus {
  loaded: boolean;
  status: CreditorsVerificationStatus;
  collectionsPermitted: boolean;
}

export const useVerificationStatus = (): UseVerificationStatus => {
  const creditor = usePrimaryCreditor();

  const status =
    creditor?.gc_sas_verifications_enabled &&
    creditor.gc_sas_remediation_outstanding_verifications
      ? CreditorsVerificationStatus.ActionRequired
      : creditor?.verification_status ?? CreditorsVerificationStatus.InReview;

  const loaded = !!creditor;
  return {
    loaded,
    status,
    collectionsPermitted: creditor?.collections_permitted ?? false,
  };
};
