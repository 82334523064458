import { Box, ColorPreset } from "@gocardless/flux-react";

import { ExportsCenterBodyHeader } from "./ExportsCentreBodyHeader";
import { onExportUpdateFunc } from "./ExportCentreConnect";
import { ExportsCentreBodyItems } from "./ExportsCentreBodyItems";
import { ExportsCentreProps } from "./ExportsCentre";

export interface ExportsCentreBodyProps extends ExportsCentreProps {
  onExportUpdate: onExportUpdateFunc;
}

const ExportsCentreBody: React.FC<ExportsCentreBodyProps> = (props) => (
  <Box style={{ maxHeight: "200px", overflowY: "scroll" }}>
    <Box bg={ColorPreset.BackgroundLight_02}>
      <Box gutterH={2} gutterV={0.5}>
        <ExportsCenterBodyHeader {...props} />
      </Box>
      <ExportsCentreBodyItems {...props} />
    </Box>
  </Box>
);

export default ExportsCentreBody;
