import {
  Box,
  Color,
  JustifyContent,
  Tag,
  TagColor,
  TagVariant,
  TagSize,
  P,
  Separator,
  Space,
  HoverEffect,
  PlainLink,
} from "@gocardless/flux-react";

import { useOutdatedBrowserVersion } from "./useOutdatedBrowserVersion";

export const OutdatedBrowserBanner = () => {
  const { shouldUpdate, updateUrl } = useOutdatedBrowserVersion();

  if (!shouldUpdate) return null;

  return (
    <>
      <Box layout="flex" justifyContent={JustifyContent.Center}>
        <Box
          layout="flex"
          gutterV={1}
          gutterH={1}
          flexDirection={["column", null, null, "row"]}
        >
          <Tag
            variant={TagVariant.Solid}
            color={TagColor.Warning}
            size={TagSize.Md}
          >
            UPDATE BROWSER
          </Tag>
          <Space layout="inline" h={1} />
          <Box layout="flex">
            <P>
              You&#39;re using an unsupported browser so you may experience
              issues.
            </P>
            &nbsp;
            <PlainLink
              href={updateUrl}
              target="_blank"
              rel="noopener noreferrer"
              layout="inline"
              textDecoration="underline"
              effect={HoverEffect.TextDecoration}
            >
              Please update here
            </PlainLink>
          </Box>
        </Box>
      </Box>
      <Separator color={Color.Warning_300} />
    </>
  );
};
