import { ExportResource } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ButtonLayout,
  JustifyContent,
  PlainLink,
  Text,
} from "@gocardless/flux-react";

import {
  ExportStatus,
  formatExportFileName,
} from "./ExportsCentreExportRowItem";

export const ExportItemRow: React.FC<ExportResource> = ({
  id,
  export_type: exportType,
  status,
  download_url: downloadUrl,
}) => (
  <>
    <Box
      layout="flex"
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
    >
      {
        <Text fontFamily="monospace" size={2}>
          {downloadUrl ? (
            <PlainLink
              href={downloadUrl}
              layout={ButtonLayout.Inline}
              data-testid={`export-download-link-${id}`}
            >
              {formatExportFileName(id, exportType)}
            </PlainLink>
          ) : (
            formatExportFileName(id, exportType)
          )}
        </Text>
      }
      <ExportStatus status={status} />
    </Box>
  </>
);
