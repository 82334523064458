import { extendAPI, addPrefixUrl } from "@gocardless/api/utils/api";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import { getUrl, Environment, isEnvironment } from "src/common/config";

import { routerPush, Route } from "../../common/routing";
import { logout } from "../authorisation";
import { checkForCookieBasedToken } from "../authorisation/session";

import { syncOrganisationIdFromResponseHeaderHook } from "./organisation-header-sync";

export {
  useOrganisationIdFromResponseHeader,
  removeOrganisationIdFromResponseHeader,
} from "./organisation-header-sync";

export type { FieldLevelError, FieldLevelErrorMap } from "./errors";

export const NO_REDIRECT_TO_404 = "noredirect";

const unAuthorisedAPIHook = async (
  request: Request,
  _options: object,
  response: Response
) => {
  const is_not_gocardless_url = !request.url.includes("gocardless.com");
  const is_not_development = !isEnvironment(Environment.Development);

  if (is_not_gocardless_url && is_not_development) return;
  if (
    /*
     * Endpoint.TemporaryAccessTokenCreate returns `401`
     * if your have multi-factor authentication, however this
     * endpoint is required to log in. So we special case this
     * endpoint.
     */
    ![
      getEndpointURL(Endpoint.TemporaryAccessTokenCreate),
      getEndpointURL(Endpoint.TemporaryAccessTokenDisableSelf),
      getEndpointURL(Endpoint.CookieBasedTokenCreate),
    ].some((endpoint) => request.url.includes(endpoint)) &&
    response.status === HTTPStatusCode.Unauthorized
  ) {
    await logout();
    /*
      When a request returns 401, we log users out correctly. For special cases where the
      merchant signed in/signed up via another service e.g. connect app and
      then try to use the dashboard, they would not have an access token in MD/ED
      and we would be logging them out incorrectly. After the 'logout' above is complete
      and the user is being route to sign-in, we make a final effort in the
      to check with payments service if this merchant has an active
       token that has not expired, if this exists we keep this
      merchant logged in before they begin to sign in all over else we do nothing
    */
    await checkForCookieBasedToken();
  }
};

/*
 * By default, we redirect to the /404 page if an API returns a 404.
 * To override this behaviour, add the endpoint to the noRedirectRoutes.
 */
const noRedirectRoutes = [
  getEndpointURL(Endpoint.UserCreateOnfidoToken),
  getEndpointURL(Endpoint.OnfidoSdkTokenCreate),
  getEndpointURL(Endpoint.AvailableCreditorRefundAmountList),
  getEndpointURL(Endpoint.PipeAccessTokenSelf, {
    pipeAccessTokenId: "OR\\d+",
  }),
  getEndpointURL(Endpoint.TemporaryAccessTokenCreateForOrganisation),
];

const notFoundAPIHook = (
  _request: Request,
  _options: object,
  response: Response
) => {
  const noRedirect = _request.headers.get(NO_REDIRECT_TO_404);
  if (
    response.status === HTTPStatusCode.NotFound &&
    !noRedirect &&
    !noRedirectRoutes.some((endpoint) => _request.url.match(endpoint))
  ) {
    routerPush({ route: Route.NotFound });
  }
};

/*
 * We add the prefix url to the API only if storybook is not running.
   This is to allow MSW to mock storybook requests on our storybook
   version deployed to production as we set a different url for storybook:
   https://storybook.gocardless.io/merchant-dashboard/index.html

   See ./storybook/preview.js
 */

if (!process.env.STORYBOOK) {
  addPrefixUrl(getUrl("api"));
}

/*
 * We extend the API to add request middle-wares.
 */
extendAPI({
  hooks: {
    afterResponse: [
      unAuthorisedAPIHook,
      notFoundAPIHook,
      syncOrganisationIdFromResponseHeaderHook,
    ],
  },
});
